@import '../variables.scss';

.bgImg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 -160px;
  width: 100%;
  height: 600px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;

  @media only screen and (max-width: 1024px) {
    background-position-y: -100px;
  }

  @media only screen and (max-width: 768px) {
    background-position-y: -30px;
  }

  @media only screen and (max-width: 535px) {
    background-position-x: -200px;
  }
}

.hero {
  padding-bottom: 152px;

  @media only screen and (max-width: 1024px) {
    padding-bottom: 100px;
  }

  @media only screen and (max-width: 768px) {
    padding-bottom: 100px;
  }

  @media only screen and (max-width: 425px) {
    padding-bottom: 110px;
  }

  .heroContent {
    width: min(621px, 70vw);
    height: 283px;
    text-align: left;
    color: white;
    margin-top: 65px;
    margin-left: 130px;

    @media only screen and (max-width: 1024px) {
      margin-left: 70px;
    }

    @media only screen and (max-width: 425px) {
      margin-left: 30px;
    }
    //border: unset;

    h1 {
      font-size: max(36px, min(56px, 7vw));
      font-weight: 600;
      line-height: max(39px, min(67px, 7vw));
      letter-spacing: 0.015em;
    }

    h2 {
      margin-top: 16px;
      font-size: 28px;
      font-weight: 500;
      line-height: 34px;
      letter-spacing: 0.005em;
    }

    h4 {
      margin-top: 30px;
      font-size: 20px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.005em;
    }

    .formRow {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 20px;

      @media only screen and (max-width: 740px) {
        flex-direction: column;
        justify-content: flex-start;
        gap: 8px;
      }
    }

    .auctionDate {
      width: 175px;
      height: 43px; // Same as buttonClear
    }

    .auctionDateError {
      color: red;
      font-size: 13px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.005em;
    }
  }
}

.requirements {

  .requirementsContent {
    margin: 50px min(6vw, 120px);
    color: $primary-font-color;

    h1 {
      font-size: max(28px, min(38px, 7.5vw));
      font-weight: 500;
      line-height: 51px;
      letter-spacing: -0.005em;
      text-align: center;

      @media only screen and (max-width: 720px) {
        text-align: left;
      }
    }

    .requirementsList {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;

      @media only screen and (max-width: 720px) {
        flex-direction: column;
      }

      .requirementItem {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        width: 48%;
        margin-bottom: 24px;

        @media only screen and (max-width: 720px) {
          width: 95%;
        }

        .icon {
          flex-grow: 0;
          flex-shrink: 0;
          height: 8px;
          width: 8px;
          background-color: #2E5B42;
          border-radius: 50%;
          display: inline-block;
          margin-right: 15px;
        }

        span {
          display: inline-block;
          margin: -8px 0 0;
          font-size: 20px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0.005em;
          text-align: left;
        }

        a {
          font-size: 20px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0.005em;
        }
      }

      .footnote, .footnote a {
        font-size: 18px !important;

        @media only screen and (max-width: 768px) {
          font-size: 16px !important;
        }
      }
    }
  }
}

.whyUs {
  background: $base-color;
  color: $primary-font-color;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 38px;
    font-style: normal;
    font-weight: 500;
    line-height: 51px;
    letter-spacing: -0.005em;
    text-align: left;
  }

  .items {
    width: 100%;
    padding-right: min(4.5vw, 120px);
    padding-left: min(4.5vw, 120px);
    margin-bottom: 73px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: 900px) {
      flex-wrap: wrap;
    }

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 30px;
      width: 25%;

      @media only screen and (max-width: 900px) {
        flex-grow: 1;
        flex-basis: 34%;
      }

      .icon {
        height: 120px;
        width: 120px;
        background-color: #2E5B42;
        border-radius: 50%;
        margin-bottom: 10px;
      }

      .sixPct {
        background-image: url('../../public/img/six_pct.svg');
        background-repeat: no-repeat;
        background-position: center;
      }

      .sixPointNinePct {
        background-image: url('../../public/img/six_point_nine_pct.svg');
        background-repeat: no-repeat;
        background-position: 50% 38%;
      }

      .mortality {
        background-image: url('../../public/img/mortality.svg');
        background-repeat: no-repeat;
        background-position: center;
      }

      .immediate {
        background-image: url('../../public/img/immediate.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-position-x: 10px;
      }

      .aplus {
        background-image: url('../../public/img/aplus.svg');
        background-repeat: no-repeat;
        background-position: center;
      }

      p {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0.005em;
        text-align: center;
      }
    }
  }
}

